<template>
  <div class="enter-package flexbox vertical">
    <header class="page-header flexbox space-bt">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ $t('PackageEdit.编辑包裹') }}</span>
      <van-icon name="ellipsis" class="text-white" />
    </header>
    <main class="flex-fill page-body">
      <div class="form round">
        <van-field
          :label="$t('PackageEdit.快递单号')"
          required
          readonly
          v-model="orderNo"
        ></van-field>
        <van-field :label="$t('PackageEdit.物品名称')" v-model="name"></van-field>
        <van-field :label="$t('PackageEdit.备注')" v-model="note"></van-field>
        <van-field :label="$t('PackageEdit.申报货值')" v-model="declarePrice"></van-field>
      </div>
      <div class="note">
        {{ $t('PackageEdit.修改包裹提示信息') }}
      </div>
      <div class="footer-btn">
        <van-button type="info" @click="onSubmit">{{$t('PackageEdit.保存')}}</van-button>
      </div>

      <van-divider />
      <van-cell-group v-show="packageLineList!=null" inset>
        <van-cell
          v-for="item in packageLineList"
          :key="item.PK_ID"
          :title="`${$t('PackageEdit.箱号')}: ${item.sBagNo}`"
          :value="`${$t('PackageEdit.品名')}: ${item.sName}`"
          :label="`${$t('PackageEdit.材质')}: ${item.sMaterialQuantity}`"
        />
      </van-cell-group>
    </main>
  </div>
</template>
<script>
import { editPkgGoods, getPackageInfoByID } from '@/api/deliver.js';
export default {
  data() {
    return {
      pkgid: 0,
      orderNo: '',
      name: '',
      note: '',
      declarePrice: 0,
      packageLineList: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.pkgid = this.$route.query.id;
      getPackageInfoByID(this.pkgid).then(res => {
        if (res.Result == true) {
          var packInfo = res.DataDict['data'];
          this.name = packInfo.goods_name;
          this.note = packInfo.remark;
          this.orderNo = packInfo.delivery_no;
          this.declarePrice = packInfo.single_price;
          this.packageLineList = packInfo.packageLineList;
        }
      });
    },
    reset() {
      this.pkgid = 0;
      this.orderNo = '';
      this.name = '';
      this.note = '';
      this.declarePrice = 0;
    },
    onSubmit() {
      editPkgGoods({
        id: this.pkgid,
        amount: this.declarePrice,
        gname: this.name,
        note: this.note,
      }).then(res => {
        if (res.Result) {
          this.$toast(res.Info);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        } else {
          this.$toast.fail(res.Info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(20px);
}
.round {
  border-radius: rem(10px);
}
.note {
  color: #b8b8b8;
  font-size: 12px;
  line-height: 1.5;
  margin: rem(20px) 0;
}
.van-button {
  height: rem(80px);
  width: rem(500px);
  background-color: $theme-color;
  border: none;
  border-radius: rem(25px);
}
.x-center {
  margin: rem(40px) 0;
}
.address {
  background: rgba(#9c9bb4, 0.3);
  padding: rem(20px);
  padding-bottom: rem(40px);
}

.indent {
  margin-left: rem(40px);
  line-height: 2;
}
.tip {
  padding: rem(32px);
  background: #f6f6f6;
  border-radius: rem(10px);
}
.text-white {
  color: #fff;
}

.footer-btn {
  width: 90%;
  margin: rem(10px) rem(20px);
  text-align: center;
}
</style>
